'use client'
import { IUser } from '@giphy/js-types'
import { useRef } from 'react'
import { IProfileUser } from 'types'
import { getAssetUrl } from '../util/asset-url'

type Props = { user: IUser; size?: number; className?: string }
const Avatar = ({ user, size = 80, className }: Props) => {
    const defaultAvatarId = useRef<number>(Math.floor(Math.random() * 5) + 1)
    const url = user.avatar_url || `https://media.giphy.com/avatars/default${defaultAvatarId.current}.gif`
    return (
        <>
            {/* eslint-disable-next-line @next/next/no-img-element */}
            <img
                suppressHydrationWarning
                src={getAssetUrl(url, size)}
                width={size}
                height={size}
                alt={user.username}
                className={className}
                onClick={() => {
                    const url = (user as IProfileUser).profile_url || `/${user.username}`
                    location.href = url
                }}
            />
        </>
    )
}

export default Avatar
