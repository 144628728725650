import { cn } from '@/util/cn'
import { IGif } from '@giphy/js-types'
import { Gif } from '@giphy/react-components'
import { LockIcon } from 'ui/src/icons/utility'

const gifHeight = 88
const gifWidth = 144
export const PlaceholderImage = ({ src, className }: { className?: string; src: string }) => {
    return <img width={gifWidth} height={gifHeight} alt="" src={src} className={cn('object-cover', className)} />
}

const noFeaturedGifImage = 'https://media.giphy.com/media/Id66GDfKacJzxSvhqV/giphy.gif'

export const CollectionGif = ({
    gif,
    isPrivate,
    isLoading,
    className,
}: {
    className?: string
    gif?: IGif
    isPrivate?: boolean
    isLoading?: boolean
}) => {
    return (
        <>
            {gif ? (
                <Gif
                    className={cn(className, '[&_img]:object-cover')}
                    gif={gif}
                    width={144}
                    height={88}
                    hideAttribution
                    noLink
                    borderRadius={0}
                    backgroundColor={gif.is_sticker ? undefined : 'rgba(0,0,0,0)'}
                />
            ) : isLoading ? (
                <div className={cn(`w-[${gifWidth}px] h-[${gifHeight}px]`, className)} />
            ) : (
                <PlaceholderImage src={noFeaturedGifImage} className={className} />
            )}
            {isPrivate && (
                <div className="absolute inset-0 flex items-center justify-center">
                    {/* @ts-ignore use css instead of color prop */}
                    <LockIcon height={25} width={40} color={null} className="fill-giphyRed" />
                </div>
            )}
        </>
    )
}

export const CollectionLabel = ({ label }: { label: string }) => {
    return <p className={'overflow-hidden text-ellipsis text-nowrap px-2 text-sm font-bold leading-6'}>{label}</p>
}

export const Divider = () => {
    return <hr className="bg-giphyDarkGrey h-0.5 w-full" />
}

export const inputClassName = 'bg-giphyBlack text-giphyWhite  box-border h-9  border-0 p-2.5'

export const cardClassName =
    'relative flex w-36 h-28 rounded-t-md cursor-pointer select-none flex-col overflow-hidden rounded-md border-solid'
