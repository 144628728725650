import { giphyUrl } from '@/app/util/env'

const SIZES = {
    large: '200h',
    medium: '80h',
    small: '36h',
}
export const getAssetUrl = (url = '', targetSize: number) => {
    let size = SIZES.large
    // small avatar looks really bad
    if (targetSize <= 30) {
        size = SIZES.small
    } else if (targetSize <= 80) {
        size = SIZES.medium
    }
    const ext = url.split('.').pop()
    const notStaging = url.replace(`staging.media.giphy.com`, `media.giphy.com`)
    return notStaging.replace(`.${ext}`, `/${size}.${ext}`)
}

export const socialServices = [
    {
        id: 'Facebook',
        icon: `${giphyUrl}/static/img/mobile/facebook.svg`,
        brandIcon: '/static/img/soc-icons/facebook@3x.png',
        bg: `#3894fc`,
    },
    {
        id: 'Twitter',
        icon: `${giphyUrl}/static/img/mobile/twitter.svg`,
        brandIcon: '/static/img/soc-icons/twitter@3x.png',
        bg: `#00ccff`,
    },
    {
        id: 'Pinterest',
        icon: `${giphyUrl}/static/img/mobile/pinterest.svg`,
        brandIcon: '/static/img/soc-icons/pinterest@3x.png',
        bg: `#e54cb5`,
    },
    {
        id: 'Reddit',
        icon: `${giphyUrl}/static/img/mobile/reddit.svg`,
        brandIcon: '/static/img/soc-icons/reddit@3x.png',
        bg: `#fc6669`,
    },
    {
        id: 'Instagram',
        icon: `${giphyUrl}/static/img/mobile/instagram.svg`,
        brandIcon: '/static/img/soc-icons/instagram@3x.png',
        bg: `#fc6669`,
    },
] as const

export const shareServices = [
    {
        id: 'SMS',
        icon: `${giphyUrl}/static/img/mobile/sms.svg`,
        brandIcon: '/static/img/soc-icons/twitter@3x.png',
        bg: `#2bfc9f`,
    },
    ...socialServices,
] as const

export const appleLogo = `${giphyUrl}/static/img/svg/apple-logo.svg`
export const fbLogo = `${giphyUrl}/static/img/soc-icons/facebook.png`

export type SocialIds = (typeof socialServices)[number]['id'] | 'Tiktok' | 'Youtube' | 'Instagram' | 'Tumblr'
