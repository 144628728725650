import { cn } from '@/util/cn'
import { ReactNode, createContext, useCallback, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import styled from 'styled-components'
import { modalZIndex } from 'ui/src/constants'
import useClientRender from '../util/use-client-render'

type Message = {
    message: ReactNode
    type?: 'success' | 'error'
    className?: string
    displayTime?: number
}
export const FlashMessageContext = createContext(
    {} as {
        showMessage: (message: Message) => void
        showStatusMessage: (success: string, failure: string, test: boolean) => void
        hideMessage: () => void
    }
)

const MessageAnimationContainer = styled.div<{ $visible?: boolean }>`
    transform: ${({ $visible }) => ($visible ? 'translateY(0)' : 'translateY(-100%)')};
    transition: transform 0.3s ease-in-out;
    z-index: ${modalZIndex + 1};
`

const FlashMessage = ({ children }: { children: ReactNode }) => {
    const [visible, setVisible] = useState(false)
    const [message, setMessage] = useState<Message>()
    const [id, setId] = useState(0)
    const showMessage = useCallback(
        (message: Message) => {
            setMessage(message)
            setVisible(true)
        },
        [setMessage, setVisible]
    )
    const showStatusMessage = useCallback(
        (success: string, failed: string, test: boolean) => {
            if (test) {
                showMessage({
                    message: success,
                    type: 'success',
                })
            } else {
                showMessage({
                    message: failed,
                    type: 'error',
                })
            }
        },
        [showMessage]
    )
    useEffect(() => {
        const timeout = setTimeout(
            () => {
                setVisible(false)
            },
            message?.displayTime || 5000
        )
        return () => clearTimeout(timeout)
    }, [id, message?.displayTime])
    const canRender = useClientRender()

    return (
        <FlashMessageContext.Provider value={{ showMessage, showStatusMessage, hideMessage: () => setVisible(false) }}>
            {canRender &&
                createPortal(
                    <MessageAnimationContainer
                        className="fixed left-0 right-0 top-0"
                        style={{ zIndex: modalZIndex + 1 }}
                        $visible={visible}
                        onTransitionEnd={() => {
                            setId(id + 1)
                        }}
                    >
                        <div
                            className={cn(
                                'text-giphyWhite flex items-center justify-center p-2.5 text-center font-bold',
                                message?.type === 'success' ? 'bg-gradient-info' : 'bg-gradient-warning',
                                message?.className
                            )}
                        >
                            {message?.message}
                        </div>
                    </MessageAnimationContainer>,
                    document.body,
                    `flash-message-${id}`
                )}
            {children}
        </FlashMessageContext.Provider>
    )
}
export default FlashMessage
