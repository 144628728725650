'use client'
import { giphyBlack } from '@giphy/colors'
import dynamic from 'next/dynamic'
import { useState } from 'react'
import { LockIcon, UnlockIcon } from 'ui/src/icons/utility'
const Toggle = dynamic(() => import('ui/src/components/controls/toggle'))

type Props = { initialIsPrivate?: boolean }
const PrivateToggle = ({ initialIsPrivate }: Props) => {
    const [isPrivate, setIsPrivate] = useState(!!initialIsPrivate)
    return (
        <div className="h-9">
            <Toggle
                labelOff={<UnlockIcon height={20} color={giphyBlack} />}
                labelOn={<LockIcon height={20} color={giphyBlack} />}
                gradientOff="blue-green"
                gradientOn="red-pink"
                isOn={isPrivate}
                onChange={(isOn) => {
                    setIsPrivate(isOn)
                }}
            />
            <input type="hidden" name="is_private" value={isPrivate ? 'true' : 'false'} />
        </div>
    )
}
export default PrivateToggle
