import { cn } from '@/util/cn'
import { InputHTMLAttributes } from 'react'

type MimeType = 'image/*' | 'video/*'
type MimeTypes = MimeType[]

type Props = Omit<InputHTMLAttributes<HTMLInputElement>, 'accept'> & {
    className?: string
    maxFileSize?: number
    accept: MimeType[]
}

export const MEDIA_TYPES = {
    VIDEO: 'video',
    IMAGE: 'image',
    GIF: 'gif',
}

export const fileSizeToMB = (size = 0) => size / (1024 * 1024)

const isVideo = (type = '') => type.indexOf('video/') > -1
const isImage = (type = '') => type.indexOf('image/') > -1
const Errors = {
    NO_FILE: 'NO_FILE',
    INVALID_FILE: 'INVALID_FILE',
    FILE_TOO_BIG: 'FILE_TOO_BIG',
    FILE_TOO_LONG: 'FILE_TOO_LONG',
    ONLY_ONE_VIDEO: 'ONLY_ONE_VIDEO',
    COMBINED_VIDEO_AND_IMAGE: 'COMBINED_VIDEO_AND_IMAGE',
    ONLY_ONE_FILE: 'ONLY_ONE_FILE',
}

export const mimeTypeToFileType = (mimeType: MimeType) => {
    if (isVideo(mimeType)) {
        return MEDIA_TYPES.VIDEO
    } else if (isImage(mimeType)) {
        return MEDIA_TYPES.IMAGE
    }
}

const checkIfValid = (fileType: string, mimeTypes: MimeTypes) =>
    mimeTypes.some((mimeType) => {
        const [type, subtype] = mimeType.split('/')
        const compare = subtype === '*' ? type : mimeType
        if (fileType.indexOf(compare) > -1) {
            return true
        }
    })

const validateFile = (file: File, mimeTypes: MimeTypes, maxDuration: number, maxFilesize: number) =>
    new Promise((resolve, reject) => {
        if (!file || !file.type) {
            // TODO how do we handle this
            reject(Errors.NO_FILE)
            return
        }

        if (!checkIfValid(file.type, mimeTypes)) {
            reject(Errors.INVALID_FILE)
            return
        }
        const size = file.size ? file.size / (1024 * 1024) : 0

        // check file size. if too big, show error
        // and reset the input value so that the
        // error shows again if the same file is
        // selected.
        if (size > maxFilesize) {
            reject(Errors.FILE_TOO_BIG)
            return
        }

        // check file type
        const fileType = mimeTypeToFileType(file.type as MimeType)
        if (!fileType) {
            reject(Errors.INVALID_FILE)
            return
        }

        const result = { file, fileType, type: 'file' }

        if (maxDuration && fileType === 'video') {
            const video = document.createElement('video')
            video.onerror = (e) => {
                console.info(`Reject file:`, e)
                reject(Errors.INVALID_FILE)
            }
            video.onloadedmetadata = () => {
                if (video.duration > maxDuration) {
                    reject(Errors.FILE_TOO_LONG)
                } else {
                    resolve(result)
                }
                URL.revokeObjectURL(video.src)
            }
            video.src = URL.createObjectURL(file)
        } else {
            resolve(result)
        }
    })

/**
 * WIP
 */
const FileInput = ({ maxFileSize = Infinity, className, accept, ...rest }: Props) => {
    return (
        <input
            onChange={async (e) => {
                if (e.target.files) {
                    console.log('File input changed')
                    for (var i = 0; i < e.target.files.length; i++) {
                        const file = e.target.files[i]
                        const valid = await validateFile(file, accept, 0, maxFileSize)
                        if (valid) {
                            console.log('File is valid')
                        } else {
                            console.log('File is invalid')
                        }
                    }
                }
            }}
            accept={accept.join(',')}
            type="file"
            {...rest}
            className={cn('bg-giphyBlack text-giphyWhite w-full px-4 py-2', className)}
        />
    )
}

export default FileInput
