'use client'
import { useImmutableChannel, useGif } from '@/api/hooks'
import { SubmitButton } from '@/components/button'
import UserContext from '@/context/user-context'
import { cn } from '@/util/cn'
import { useContext, useState } from 'react'
import { CreateCollectionForm } from '../action-buttons'
import PrivateToggle from '../private-toggle'
import { CollectionGif, CollectionLabel, Divider, cardClassName, inputClassName } from '../ui'

type Props = { channel?: string; id?: string }

const CreatePanel = ({ id }: Props) => {
    const { user } = useContext(UserContext)
    const { data: channel } = useImmutableChannel(user?.channel_id)
    const { data, isLoading: gifLoading } = useGif(id)
    const [displayName, setDisplayName] = useState('')
    return data ? (
        <CreateCollectionForm className="flex flex-col items-center gap-2 p-2 font-bold" featuredGif={data}>
            <div className={cn(cardClassName, 'bg-giphyIndigo')}>
                <CollectionGif gif={data} isLoading={gifLoading} />
                <CollectionLabel label={displayName || 'New Collection'} />
            </div>
            <PrivateToggle />
            <input type="hidden" name="user" value={user?.id} />
            <input type="hidden" name="parent" value={channel?.id} />
            <label className="text-giphyWhite self-start" htmlFor="channel">
                Name
            </label>
            <input
                type="text"
                spellCheck={false}
                value={displayName}
                onChange={(e) => setDisplayName(e.target.value)}
                placeholder={'Collection Name'}
                name="display_name"
                autoComplete="off"
                autoCorrect="off"
                className={cn(inputClassName, 'peer/input w-full')}
            />
            <Divider />
            <SubmitButton
                className="
                w-full cursor-pointer select-none
                peer-placeholder-shown/input:pointer-events-none
                peer-placeholder-shown/input:opacity-50
                "
            >
                Create Collection
            </SubmitButton>
        </CreateCollectionForm>
    ) : null
}
export default CreatePanel
