'use client'

import { FlashMessageContext } from '@/context/flash-message'
import { cn } from '@/util/cn'
import { giphyLocalUrl } from '@/app/util/env'
import { getCookie, getPost } from '@/util/url'
import { IChannel } from '@giphy/js-types'
import { ReactNode, useContext } from 'react'
import { useSWRConfig } from 'swr'
import { ModalContext } from '../modal'
import { IGif } from '@giphy/js-types'
import { timeoutFetch } from 'utils/src/api/fetch'

type Props = { className?: string; children: React.ReactNode; gifId?: string; channelId: number }

export const AddToCollectionButton = ({ className, children, gifId, channelId }: Props) => {
    const csrftoken = getCookie('csrftoken')
    const { showStatusMessage } = useContext(FlashMessageContext)
    const { setModalType } = useContext(ModalContext)
    return (
        <div
            className={cn(className)}
            onClick={async () => {
                if (gifId) {
                    const result = await timeoutFetch({
                        url: `${giphyLocalUrl}/api/v4/channel-gifs`,
                        options: {
                            ...getPost(csrftoken),
                            body: JSON.stringify({
                                items: [
                                    {
                                        gif: gifId,
                                        channel: channelId,
                                    },
                                ],
                            }),
                        },
                    })
                    showStatusMessage('Added to collection', 'Failed to add to collection', result.ok)
                    setModalType(null)
                }
            }}
        >
            {children}
        </div>
    )
}
export const CreateCollectionForm = ({
    className,
    children,
    featuredGif,
}: {
    className: string
    children: ReactNode
    featuredGif: IGif
}) => {
    const csrftoken = getCookie('csrftoken')
    const { showMessage } = useContext(FlashMessageContext)
    const { mutate } = useSWRConfig()
    const { goBack } = useContext(ModalContext)
    return (
        <form
            autoComplete="off"
            className={className}
            action={async (formData: FormData) => {
                const result = await timeoutFetch({
                    url: `${giphyLocalUrl}/api/v4/channels`,
                    options: {
                        ...getPost(csrftoken),
                        body: JSON.stringify(Object.fromEntries(formData)),
                    },
                })
                if (result.ok) {
                    const newChannel = (await result.json()) as IChannel
                    const addGifResult = await timeoutFetch({
                        url: `${giphyLocalUrl}/api/v4/channel-gifs`,
                        options: {
                            ...getPost(csrftoken),
                            body: JSON.stringify({
                                items: [
                                    {
                                        gif: featuredGif.id,
                                        channel: newChannel.id,
                                    },
                                ],
                            }),
                        },
                    })
                    if (addGifResult.ok) {
                        showMessage({
                            message: 'Created collection',
                            type: 'success',
                        })
                        await optimisticUpdateParent({ ...newChannel, featured_gif: featuredGif }, mutate)
                        goBack()
                    }
                } else {
                    showMessage({
                        message: 'Failed to create to collection',
                        type: 'error',
                    })
                }
            }}
        >
            {children}
        </form>
    )
}

export const EditCollectionForm = ({
    className,
    children,
    channelId,
}: {
    channelId: number
    className: string
    children: ReactNode
}) => {
    const { showStatusMessage } = useContext(FlashMessageContext)
    const { goBack } = useContext(ModalContext)
    const { mutate } = useSWRConfig()
    return (
        <form
            id="editForm"
            autoComplete="off"
            className={className}
            action={async (formData: FormData) => {
                const result = await timeoutFetch({
                    url: `${giphyLocalUrl}/api/v4/channels/${channelId}`,
                    options: {
                        ...getPost(getCookie('csrftoken')),
                        method: 'PATCH',
                        body: JSON.stringify(Object.fromEntries(formData)),
                    },
                })
                showStatusMessage('Collection Saved', 'Failed to update collection', result.ok)
                if (result.ok) {
                    const updatedChannel = (await result.json()) as IChannel
                    await mutate(`${giphyLocalUrl}/api/v4/channels/${updatedChannel.id}`, updatedChannel, {
                        optimisticData: updatedChannel,
                        revalidate: false,
                    })
                    await optimisticUpdateParent(updatedChannel, mutate)
                    goBack()
                }
            }}
        >
            {children}
        </form>
    )
}

// fetch the parent, and mutate the cache
async function optimisticUpdateParent(updatedChannel: IChannel, mutate: any) {
    const parent = (await (
        await timeoutFetch({
            url: `${giphyLocalUrl}/api/v4/channels/${updatedChannel.parent}`,
            options: {
                method: 'GET',
            },
        })
    ).json()) as IChannel
    let children
    if (parent.children.find((c) => c.id === updatedChannel.id)) {
        children = parent.children.map((c) => (c.id === updatedChannel.id ? updatedChannel : c))
    } else {
        children = [...parent.children, updatedChannel]
    }
    const optimisticData = {
        ...parent,
        children,
    }
    await mutate(`${giphyLocalUrl}/api/v4/channels/${parent.id}`, optimisticData, {
        optimisticData,
        revalidate: false,
    })
}
