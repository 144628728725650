import { GiphyFetch } from '@giphy/js-fetch-api'
import { useMemo, useContext } from 'react'
import { publicRuntimeConfig } from '@/app/util/env'
import UAParserContext from '@/context/ua-parser'

/* client-side utilities */

export const useApiKey = () => {
    const { deviceType } = useContext(UAParserContext)
    return deviceType === 'mobile' ? publicRuntimeConfig.mobileApiKey! : publicRuntimeConfig.apiKey!
}

export const useGiphyFetch = () => {
    const apiKey = useApiKey()
    const gf = useMemo(() => {
        return new GiphyFetch(apiKey)
    }, [apiKey])
    return gf
}
