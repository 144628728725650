'use client'
import { ReactNode, createContext, useContext } from 'react'

type Type = 'mobile' | 'desktop' | undefined

export type UAParserProps = {
    deviceType: Type
}

const UAParserContext = createContext({
    deviceType: undefined,
} as UAParserProps)

export const RenderProps = ({ children }: { children: (deviceType: Type) => ReactNode }) => {
    const { deviceType } = useContext(UAParserContext)
    return children(deviceType)
}

export default UAParserContext
