'use client'
import Avatar from '@/components/avatar'
import Username from '@/components/username'
import { publicRuntimeConfig } from '@/app/util/env'
import { IChannel, IUser } from '@giphy/js-types'
import { SearchBar, SearchContext, SearchContextManager } from '@giphy/react-components'
import { useContext, useEffect, useState } from 'react'
import { ModalContext } from '../modal'

const FetchChannels = () => {
    const { setModalType } = useContext(ModalContext)
    const { fetchChannelSearch, term, setChannels, currentChannels, isFocused, setSearch } = useContext(SearchContext)
    const [delayedFocus, setDelayedFocus] = useState(isFocused)
    useEffect(() => {
        const doFetch = async () => {
            const re = await fetchChannelSearch(0)
            setChannels(re)
        }
        if (term) {
            doFetch()
        } else {
            setChannels([])
        }
    }, [term, fetchChannelSearch, setChannels])
    useEffect(() => {
        let timeout: NodeJS.Timeout
        if (!isFocused) {
            // let a click event propagate even after blur
            timeout = setTimeout(() => setDelayedFocus(false), 100)
        } else {
            setDelayedFocus(true)
        }
        return () => clearTimeout(timeout)
    }, [isFocused])
    return delayedFocus && currentChannels?.length > 0 ? (
        <div className="relative">
            <div className="bg-giphyDarkCharcoal divide-giphyWhite absolute top-1 z-10 max-h-80 w-full divide-y divide-dashed divide-opacity-20 overflow-auto rounded-md *:px-3 *:py-3">
                {currentChannels
                    ? currentChannels?.map((channel: IChannel) => {
                          return (
                              <div
                                  className="group flex cursor-pointer items-center gap-2"
                                  key={channel.id}
                                  onClick={(e) => {
                                      e.preventDefault()
                                      e.stopPropagation()
                                      setSearch('')
                                      setModalType({ name: 'addToCollection', channel: channel.id })
                                  }}
                              >
                                  <Avatar user={channel.user} className="size-12" />
                                  <div className="group-hover:text-giphyBlue select-none">
                                      <div key={channel.id}>{channel.display_name}</div>
                                      <Username user={channel.user} />
                                  </div>
                              </div>
                          )
                      })
                    : null}
            </div>
        </div>
    ) : null
}

type Props = { user: IUser; onSelect: (channel: IChannel['id']) => void }
const SearchInput = ({ user }: Props) => {
    return (
        <SearchContextManager
            initialTerm={user.display_name}
            apiKey={publicRuntimeConfig.apiKey}
            shouldFetchChannels={false}
            theme={{
                darkMode: true,
            }}
        >
            <SearchBar className="flex w-full flex-1" placeholder="Search for Channels" />
            <FetchChannels />
        </SearchContextManager>
    )
}
export default SearchInput
