import { createContext } from 'react'
import { ILoggedInUser } from 'types'

export type UserContextProps = {
    user?: ILoggedInUser | null | undefined
    hasFetchedUser: boolean
}

const UserContext = createContext({
    user: {} as ILoggedInUser,
    hasFetchedUser: false,
} as UserContextProps)

export default UserContext
