import { useEffect, useState } from 'react'

function useClientRender() {
    const [canRender, setRender] = useState(false)
    useEffect(() => {
        setRender(true)
    }, [])
    return canRender
}

export default useClientRender
