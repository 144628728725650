import { cn } from '@/util/cn'

const Loader = ({ className }: { className?: string }) => (
    <div className={cn('loader', className)}>
        <div />
        <div />
        <div />
        <div />
        <div />
    </div>
)

export default Loader
