'use client'
import { useImmutableChannel } from '@/api/hooks'
import UserContext from '@/context/user-context'
import { cn } from '@/util/cn'
import { useContext } from 'react'
import { ILoggedInUser } from 'types'
import Loader from '../loader'
import { ModalContext } from '../modal'
import { AddToCollectionButton } from './action-buttons'
import SearchInput from './search-input'
import { CollectionGif, CollectionLabel, PlaceholderImage, cardClassName } from './ui'
import { IChannel } from '@giphy/js-types'
import { AddIcon } from 'ui/src/icons/utility'

type Props = { channelId?: IChannel['id']; gifId?: string }

const newCollectionImage = 'https://media.giphy.com/media/fTytsfKCVyb6lB75jM/giphy.gif'
const peerClassName = 'peer box-content border-2 hover:z-10 border-giphyDarkestGrey'
const AddToCollection = ({ channelId: channelId, gifId: id }: Props) => {
    const { user } = useContext(UserContext)
    const isSubChannel = !!channelId
    const { setModalType } = useContext(ModalContext)
    const { data: channel, isLoading } = useImmutableChannel(channelId || user?.channel_id)
    const channels = channel?.children || []
    if (!user) return null

    return (
        <div className="flex flex-col gap-2 p-2">
            <SearchInput
                user={user as ILoggedInUser}
                onSelect={(channelId: IChannel['id']) => {
                    setModalType({
                        name: 'addToCollection',
                        channel: channelId,
                    })
                }}
            />
            <div
                className="animate-fade-in flex max-h-80 flex-wrap justify-start gap-4 overflow-y-auto pt-2"
                key={`container-${isLoading}`}
            >
                {isLoading ? (
                    <Loader />
                ) : (
                    <>
                        {isSubChannel && channel && (
                            <AddToCollectionButton
                                channelId={channel.id}
                                gifId={id}
                                className={cn(
                                    cardClassName,
                                    'striped-background',
                                    `hover:border-giphyLightCharcoal basis-full`,
                                    'flex items-center justify-center',
                                    peerClassName
                                )}
                            >
                                <AddIcon className="" height={14} width={14} />
                                <CollectionLabel label={`Add to ${channel?.display_name}`} />
                            </AddToCollectionButton>
                        )}
                        <div
                            onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                setModalType({
                                    name: 'createCollection',
                                    channel: channelId,
                                    title: channel?.display_name,
                                })
                            }}
                            className={cn(
                                cardClassName,
                                'bg-giphyLightCharcoal hover:border-giphyLightCharcoal',
                                peerClassName
                            )}
                        >
                            <PlaceholderImage src={newCollectionImage} />
                            <CollectionLabel label={`New ${isSubChannel ? 'Sub' : ''}-Collection`} />
                        </div>
                        {channels?.map((subChannel) => {
                            const children = (
                                <>
                                    <CollectionGif gif={subChannel.featured_gif} isPrivate={subChannel.is_private} />
                                    <CollectionLabel label={subChannel.short_display_name || subChannel.display_name} />
                                </>
                            )
                            return (
                                <div
                                    key={subChannel.id}
                                    className={cn(
                                        cardClassName,
                                        `[&:nth-child(1n)]:bg-giphyRed
                                        [&:nth-child(2n)]:bg-giphyLightBlue
                                        [&:nth-child(3n)]:bg-giphyIndigo
                                        [&:nth-child(4n)]:bg-giphyPink
                                        hover:[&:nth-child(1n)]:border-giphyRed
                                        hover:[&:nth-child(2n)]:border-giphyLightBlue
                                        hover:[&:nth-child(3n)]:border-giphyIndigo
                                        hover:[&:nth-child(4n)]:border-giphyPink`,
                                        peerClassName
                                    )}
                                    onClick={(e) => {
                                        e.preventDefault()
                                        e.stopPropagation()
                                    }}
                                >
                                    {subChannel.num_children === 0 ? (
                                        <AddToCollectionButton channelId={subChannel.id} gifId={id}>
                                            {children}
                                        </AddToCollectionButton>
                                    ) : (
                                        <div
                                            onClick={() => {
                                                setModalType({
                                                    name: 'addToCollection',
                                                    channel: subChannel.id,
                                                    title: subChannel.display_name,
                                                })
                                            }}
                                        >
                                            {children}
                                            <div className="text-giphyWhite bg-giphyBlack border-1 absolute right-1 top-1 rounded-lg bg-opacity-80 p-1 text-center text-xs font-bold">
                                                +{subChannel.num_children} more
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )
                        })}

                        <div className="bg-giphyBlack duration-250 pointer-events-none absolute inset-0 z-0 opacity-0 transition-opacity peer-hover:opacity-40" />
                    </>
                )}
            </div>
        </div>
    )
}
export default AddToCollection
