import { cn } from '@/util/cn'
import { IUser } from '@giphy/js-types'
import { VerifiedBadge as VerifiedBadge_ } from '@giphy/react-components'
import styled from 'styled-components'

const Container = styled.div`
    & > :first-child {
        -webkit-font-smoothing: subpixel-antialiased;
        margin-right: 1px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`
const VerifiedBadge = styled(VerifiedBadge_)`
    flex-shrink: 0;
`

type Props = { user: IUser; badgeSize?: number; className?: string; alternateName?: string }
const Username = ({ user, badgeSize = 14, className, alternateName = '' }: Props) => (
    <Container
        className={cn('text-giphyWhiteSmoke flex cursor-pointer items-center text-sm font-bold', className)}
        onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            // TODO once desktop channels are ported to nextjs, use Link
            location.href = `/${user.username}`
        }}
    >
        <div>{alternateName || `@${user.username}`}</div>
        {user.is_verified && <VerifiedBadge size={badgeSize} />}
    </Container>
)

export default Username
