'use client'
import AddToCollection from '@/app/components/collections/add-to-collection'
import CreatePanel from '@/app/components/collections/create/panel'
import EditPanel from '@/app/components/collections/edit/panel'
import { Modal, ModalContext, ModalType } from '@/app/components/modal'
import { ReactNode, useEffect, useState } from 'react'

export default function ModalManager({ gifId: id, children }: { gifId: string; children: ReactNode }) {
    const [modal, setModalType] = useState<ModalType>(null)
    const [modalHistory, setModalHistory] = useState<ModalType[]>([])
    useEffect(() => {
        if (modal === null) {
            /// clear history
            if (modalHistory.length > 0) {
                setModalHistory([])
            }
        } else if (modal !== modalHistory[modalHistory.length - 1]) {
            // append to history
            setModalHistory([...modalHistory, modal])
        }
    }, [modal, modalHistory])
    // get previous modal
    const previousModal = modalHistory[modalHistory.length - 2]
    const goBack = () => {
        setModalHistory(modalHistory.slice(0, -1))
        setModalType(previousModal)
    }
    return (
        <ModalContext.Provider
            value={{
                modal,
                setModalType,
                goBack,
            }}
        >
            {children}
            {modal ? (
                <Modal
                    previousModalId={previousModal}
                    title={modal.title || getTitle(modal.name)}
                    goBack={goBack}
                    onClose={() => setModalType(null)}
                >
                    {modal.name === 'addToCollection' ? (
                        <AddToCollection gifId={id} channelId={modal.channel} />
                    ) : modal.name === 'createCollection' ? (
                        <CreatePanel id={id} />
                    ) : modal.name === 'editCollection' ? (
                        <EditPanel />
                    ) : null}
                </Modal>
            ) : null}
        </ModalContext.Provider>
    )
}

function getTitle(modal: string | null) {
    let title = 'Add To Collection'
    switch (modal) {
        case 'createCollection':
            title = 'Create Collection'
            break
        case 'editCollection':
            title = 'Edit Collection'
            break
    }
    return title
}
